import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject, retry } from "rxjs";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie";
import jwt_decode from "jwt-decode";
import {
  SocialAuthService,
  GoogleLoginProvider,
} from "@abacritt/angularx-social-login";
import _ from "lodash";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class SharedService {
  public afterVerification: boolean = false;
  project_name: any;
  public designationSubject: Subject<any> = new Subject<any>();
  public changeDesignationRedirect: Subject<any> = new Subject<any>();
  public errorNotifier: Subject<any> = new Subject<any>();
  public dashboardFilterApplied = new BehaviorSubject({});
  public isDataInFilter: boolean = false;
  public listProjectStore: any;
  public unSaveDataSubject: Subject<any> = new Subject<any>();

  public resetNameDealIdDropdown: Subject<string> = new Subject<string>();

  encryptMode: boolean;
  textToConvertDesignation: string;
  textToConvertEmail: string;
  passworddes: string;
  passwordemail: string;
  conversionOutput: string;
  decoded: any;
  accessToken: any;
  constructor(
    private http: HttpClient,
    private _router: Router,
    private _cookieService: CookieService,
    private authService: SocialAuthService
  ) {
    this.passworddes = "desc";
    this.passwordemail = "email";
  }

  getProjectStatus(deal_id) {
    let body = {
      deal_id: null,
    };
    body.deal_id = deal_id;
    return this.http.post(environment.base_url + "project_status_api/", body);
  }

  getListFilters(name) {
    let key = !localStorage.getItem("FilterKey")
      ? ""
      : localStorage.getItem("FilterKey");
    let value = !localStorage.getItem("FilterValue")
      ? ""
      : localStorage.getItem("FilterValue");
    return {
      key: key,
      selectedValue: value,
    };
  }
  getAccessToken() {
    if (!this._cookieService.get("access_token")) {
      this.authService
        .getAccessToken(GoogleLoginProvider.PROVIDER_ID)
        .then((accessToken) => {
          this._cookieService.put("access_token", accessToken);
          return accessToken;
        });
    } else {
      return this._cookieService.get("access_token");
    }
  }
  /*
  For Exporting csv to google sheets
  */
  addFile(blob, filename) {
    var access_token;
    this.isTokenExpired(this._cookieService.get("access_token")).then((res) => {
      if (this._cookieService.get("access_token") && !res) {
        access_token = this._cookieService.get("access_token");
        this.sendFile(blob, filename, access_token);
      } else {
        this.authService
          .getAccessToken(GoogleLoginProvider.PROVIDER_ID)
          .then((accessToken) => {
            this._cookieService.put("access_token", accessToken);
            access_token = accessToken;
          })
          .then((res) => {
            this.sendFile(blob, filename, access_token);
          });
      }
    });
  }
  isTokenExpired(token: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          "https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=" + token
        )
        .subscribe(
          (res) => {
            const expires = new Date(res["exp"] * 1000);
            const currentTime = new Date();
            if (expires.getTime() < currentTime.getTime()) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }
  sendFile(blob, filename, access_token) {
    var contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var metadata = {
      title: filename,
      mimeType: contentType,
    };
    var accessToken = localStorage.getItem("auth_token");
    var accessToken = localStorage.getItem("idToken");
    // var accessToken = this._cookieService.get('idToken');
    // var accessToken = this._cookieService.get("");
    var form = new FormData();
    form.append(
      "metadata",
      new Blob([JSON.stringify(metadata)], { type: "application/json" })
    );
    form.append("file", new Blob([blob]));
    var xhr = new XMLHttpRequest();

    xhr.open(
      "POST",
      "https://www.googleapis.com/upload/drive/v2/files?uploadType=multipart&convert=true",
      true
    );

    xhr.setRequestHeader("Authorization", "Bearer " + access_token);
    xhr.send(form);
    xhr.responseType = "json";
    xhr.onload = () => {
      const a = document.createElement("a");
      a.href = `https://docs.google.com/spreadsheets/d/${xhr.response.id}`;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    return;
    // });
  }
  setProjectRoutingDetails(
    routeURL: string,
    projectName: string,
    pillar?: string
  ) {
    localStorage.setItem("routeURL", routeURL);
    localStorage.setItem("project", projectName);
    if (pillar)
      this._cookieService.put("_tsl", pillar == "CloudOps" ? "devops" : pillar);
  }

  setListFilters(key: string, selectedValue: string) {
    localStorage.setItem("FilterKey", key);
    localStorage.setItem("FilterValue", selectedValue);
  }

  getstage() {
    return localStorage.getItem("DashboardRole");
  }

  setstage(role: string) {
    localStorage.setItem("DashboardRole", role);
  }

  getProjectCreationToggle() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      return this.decoded["project_creation"];
    }
  }
  // setProjectCreationToggle(flag) {
  //   localStorage.setItem("project_c", flag);
  // }

  public getDesignation() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      if (this._cookieService.get("DG")) {
        if (this.decoded["designation"].includes(this._cookieService.get("DG")))
          return this._cookieService.get("DG");
        else {
          localStorage.clear();
          this._cookieService.removeAll();
          this._router.navigate(["/home"]);
        }
      } else {
        return this._cookieService.get("DG");
      }
    }
  }

  //For project list filters
  setApplyFilterAccountName(filter) {
    if (filter == "") this._cookieService.remove("_flt_acc");
    this._cookieService.put("_flt_acc", btoa(filter.toString()));
  }
  public getApplyFilterAccountName() {
    if (this._cookieService.get("_flt_acc")) {
      return atob(this._cookieService.get("_flt_acc"));
    } else return "";
  }

  setApplyFilterGTMLead(filter) {
    if (filter == "") this._cookieService.remove("_flt_gtm");
    this._cookieService.put("_flt_gtm", btoa(filter.toString()));
  }
  public getApplyFilterGTMLead() {
    if (this._cookieService.get("_flt_gtm")) {
      return atob(this._cookieService.get("_flt_gtm"));
    } else return "";
  }
  public getApplyFilterExtendedDealIds() {
    if (this._cookieService.get("_flt_ext_d")) {
      return atob(this._cookieService.get("_flt_ext_d"));
    } else return "";
  }
  setApplyFilterOU(filter) {
    if (filter == "") this._cookieService.remove("_flt_ou");
    this._cookieService.put("_flt_ou", btoa(filter.toString()));
  }
  public getApplyFilterOU() {
    if (this._cookieService.get("_flt_ou")) {
      return atob(this._cookieService.get("_flt_ou"));
    } else return "";
  }
  setApplyFilterOUHead(filter) {
    if (filter == "") this._cookieService.remove("_flt_ouhead");
    this._cookieService.put("_flt_ouhead", btoa(filter.toString()));
  }
  public getApplyFilterOUHead() {
    if (this._cookieService.get("_flt_ouhead")) {
      return atob(this._cookieService.get("_flt_ouhead"));
    } else return "";
  }
  setApplyFilterPLDAGSPOC(filter) {
    if (filter == "") this._cookieService.remove("_flt_dag_spoc");
    this._cookieService.put("_flt_dag_spoc", btoa(filter.toString()));
  }
  public getApplyFilterPLDAGSPOC() {
    if (this._cookieService.get("_flt_dag_spoc")) {
      return atob(this._cookieService.get("_flt_dag_spoc"));
    } else return "";
  }

  setApplyFilterPriPrjHierarchy(filter) {
    if (filter == "") this._cookieService.remove("_flt_pri_prj_hierarchy");
    this._cookieService.put("_flt_pri_prj_hierarchy", btoa(filter.toString()));
  }
  public getApplyFilterPriPrjHierarchy() {
    if (this._cookieService.get("_flt_pri_prj_hierarchy")) {
      return atob(this._cookieService.get("_flt_pri_prj_hierarchy"));
    } else return "";
  }
  setApplyTrackApplicable(filter) {
    if (filter == "") this._cookieService.remove("_flt_track_applicable");
    this._cookieService.put("_flt_track_applicable", btoa(filter.toString()));
  }
  getApplyTrackApplicable() {
    if (this._cookieService.get("_flt_track_applicable")) {
      return atob(this._cookieService.get("_flt_track_applicable"));
    } else return "";
  }

  setApplyFilterProjectLead(filter) {
    if (filter == "") this._cookieService.remove("_flt_pl");
    this._cookieService.put("_flt_pl", btoa(filter.toString()));
  }
  public getApplyFilterProjectLead() {
    if (this._cookieService.get("_flt_pl")) {
      return atob(this._cookieService.get("_flt_pl"));
    } else return "";
  }
  setApplyFilterSA(filter) {
    if (filter == "") this._cookieService.remove("_flt_sa");
    this._cookieService.put("_flt_sa", btoa(filter.toString()));
  }
  public getApplyFilterSA() {
    if (this._cookieService.get("_flt_sa")) {
      return atob(this._cookieService.get("_flt_sa"));
    } else return "";
  }
  setApplyFilterStage(filter) {
    if (filter == "") this._cookieService.remove("_flt_stg");
    this._cookieService.put("_flt_stg", btoa(filter.toString()));
  }
  public getApplyFilterStage() {
    if (this._cookieService.get("_flt_stg")) {
      return atob(this._cookieService.get("_flt_stg"));
    } else return "";
  }
  public setApplyFilterExtendedDealIds(filter) {
    if (filter == "") this._cookieService.remove("_flt_ext_d");
    this._cookieService.put("_flt_ext_d", btoa(filter.toString()));
  }
  setApplyFilterStatus(filter) {
    if (filter == "") this._cookieService.remove("_flt_stat");
    this._cookieService.put("_flt_stat", btoa(filter.toString()));
  }
  public getApplyFilterStatus() {
    if (this._cookieService.get("_flt_stat")) {
      return atob(this._cookieService.get("_flt_stat"));
    } else return "";
  }

  setApplyFilterStartDate(filter) {
    if (filter == "") this._cookieService.remove("_flt_std");
    this._cookieService.put("_flt_std", btoa(filter.toString()));
  }
  public getApplyFilterStartDate() {
    if (this._cookieService.get("_flt_std")) {
      return atob(this._cookieService.get("_flt_std"));
    } else return "";
  }
  setApplyFilterEndDate(filter) {
    if (filter == "") this._cookieService.remove("_flt_etd");
    this._cookieService.put("_flt_etd", btoa(filter.toString()));
  }
  public getApplyFilterEndDate() {
    if (this._cookieService.get("_flt_etd")) {
      return atob(this._cookieService.get("_flt_etd"));
    } else return "";
  }

  //For Project Database Filters
  setApplyFilterDAGSpoc(filter) {
    if (filter == "") this._cookieService.remove("_pdb_dag");
    this._cookieService.put("_pdb_dag", btoa(filter.toString()));
  }
  public getApplyFilterDAGSpoc() {
    if (this._cookieService.get("_pdb_dag")) {
      return atob(this._cookieService.get("_pdb_dag"));
    } else return "";
  }

  setApplyFilterIsExtension(filter) {
    if (filter == "") this._cookieService.remove("_pdb_ext");
    this._cookieService.put("_pdb_ext", btoa(filter.toString()));
  }
  public getApplyFilterIsExtension() {
    if (this._cookieService.get("_pdb_ext")) {
      return atob(this._cookieService.get("_pdb_ext"));
    } else return "";
  }

  setApplyFilterManagerId(filter) {
    if (filter == "") this._cookieService.remove("_pdb_man");
    this._cookieService.put("_pdb_man", btoa(filter.toString()));
  }
  public getApplyFilterManagerId() {
    if (this._cookieService.get("_pdb_man")) {
      return atob(this._cookieService.get("_pdb_man"));
    } else return "";
  }

  setApplyFilterOUProjectDatabase(filter) {
    if (filter == "") this._cookieService.remove("_pdb_ou");
    this._cookieService.put("_pdb_ou", btoa(filter.toString()));
  }
  public getApplyFilterOUProjectDatabase() {
    if (this._cookieService.get("_pdb_ou")) {
      return atob(this._cookieService.get("_pdb_ou"));
    } else return "";
  }

  setApplyFilterTrack(filter) {
    if (filter == "") this._cookieService.remove("_pdb_tra");
    this._cookieService.put("_pdb_tra", btoa(filter.toString()));
  }
  public getApplyFilterTrack() {
    if (this._cookieService.get("_pdb_tra")) {
      return atob(this._cookieService.get("_pdb_tra"));
    } else return "";
  }

  setApplyFilterKickoffDate(filter) {
    if (filter == "") this._cookieService.remove("_pdb_kof");
    this._cookieService.put("_pdb_kof", btoa(filter.toString()));
  }
  public getApplyFilterKickoffDate() {
    if (this._cookieService.get("_pdb_kof")) {
      return atob(this._cookieService.get("_pdb_kof"));
    } else return "";
  }

  setApplyFilterConsultationDate(filter) {
    if (filter == "") this._cookieService.remove("_pdb_con");
    this._cookieService.put("_pdb_con", btoa(filter.toString()));
  }
  public getApplyFilterConsultationDate() {
    if (this._cookieService.get("_pdb_con")) {
      return atob(this._cookieService.get("_pdb_con"));
    } else return "";
  }

  setApplyFilterAuditDate(filter) {
    if (filter == "") this._cookieService.remove("_pdb_adc");
    this._cookieService.put("_pdb_adc", btoa(filter.toString()));
  }
  public getApplyFilterAuditDate() {
    if (this._cookieService.get("_pdb_adc")) {
      return atob(this._cookieService.get("_pdb_adc"));
    } else return "";
  }

  setApplyFilterConsultCompleted(filter) {
    if (filter == "") this._cookieService.remove("_pdb_coc");
    this._cookieService.put("_pdb_aud", btoa(filter.toString()));
  }
  public getApplyFilterConsultCompleted() {
    if (this._cookieService.get("_pdb_coc")) {
      return atob(this._cookieService.get("_pdb_coc"));
    } else return "";
  }
  // setApplyFilterNoMoreAudits(filter) {
  //   if (filter == "") this._cookieService.remove("_pdb_nma");
  //   this._cookieService.put("_pdb_nma", btoa(filter.toString()));
  // }
  // public getApplyFilterNoMoreAudits() {
  //   if (this._cookieService.get("_pdb_nma")) {
  //     return atob(this._cookieService.get("_pdb_nma"));
  //   } else return "";
  // }
  setApplyFilterProjectDuration(filter) {
    if (filter == "") this._cookieService.remove("_pdb_prd");
    this._cookieService.put("_pdb_prd", btoa(filter.toString()));
  }
  public getApplyFilterProjectDuration() {
    if (this._cookieService.get("_pdb_prd")) {
      return atob(this._cookieService.get("_pdb_prd"));
    } else return "";
  }

  setApplyFilter(filter, cookieName) {
    if (filter == "") this._cookieService.remove(cookieName);
    this._cookieService.put(cookieName, btoa(filter.toString()));
  }

  public getApplyFilter(cookieName) {
    if (this._cookieService.get(cookieName)) {
      return atob(this._cookieService.get(cookieName));
    } else return "";
  }

  public getSelectedAuditCycleId() {
    if (this._cookieService.get("_accd")) {
      return Number(atob(this._cookieService.get("_accd")));
    }
  }
  public setSelectedAuditCycleId(audit_cycle_id) {
    this._cookieService.put("_accd", btoa(audit_cycle_id));
  }
  public setDealId(deal_id: any) {
    if (deal_id == "undefined" || deal_id == undefined) deal_id = null;
    this._cookieService.put("_dll", btoa(deal_id));
  }
  public getDealId() {
    if (this._cookieService.get("_dll"))
      return String(atob(this._cookieService.get("_dll")));
  }
  public setDesignation(designation: string) {
    this._cookieService.put("DG", designation);
  }
  public getSessionTime() {
    if (this._cookieService.get("_sst")) {
      return atob(this._cookieService.get("_sst"));
    }
  }
  public setSessionTime(time) {
    this._cookieService.put("_sst", btoa(time));
  }
  public setUnsaveDataFlag(flag) {
    this._cookieService.put("_dsf", btoa(flag));
  }
  public getUnsaveDataFlag() {
    if (this._cookieService.get("_dsf")) {
      return atob(this._cookieService.get("_dsf"));
    }
  }
  public getTeam() {
    if (localStorage.getItem("auth_token"))
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
    // this.decoded["team"].forEach(function (part, index, theArray) {
    //   if (theArray[index] == 'devops')
    //     theArray[index] = "CloudOps";
    // });
    // this.team = this.decoded["team"];
    return this.decoded["team"]
      .filter(function (el) {
        return el != null;
      })
      .toString();
  }

  // public setTeam(team: string) {
  //   localStorage.setItem("Team", team);
  // }

  public getSelectedTeam() {
    if (this._cookieService.get("_tsl")) {
      if (localStorage.getItem("auth_token"))
        this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      if (this.decoded["team"].includes(atob(this._cookieService.get("_tsl"))))
        return atob(this._cookieService.get("_tsl"));
      else {
        localStorage.clear();
        this._cookieService.removeAll();
        this._router.navigate(["/home"]);
      }
    } else {
      return "";
    }

    // return localStorage.getItem("_tsl");
  }

  public setSelectedTeam(team: string) {
    this._cookieService.put("_tsl", btoa(team == "CloudOps" ? "devops" : team));
    // localStorage.setItem("_tsl", team);
  }
  public getEncodeToken() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      return this.decoded;
    }
  }
  public getEncodeUserEmail() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      return this.decoded["email_id"];
    }
  }
  public getEncodeUserJobRole() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      return this.decoded["job_role"];
    }
  }
  public getEncodeUserManagerName() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      return this.decoded["manager_email_id"];
    }
  }
  public getEncodeUserEmployeeId() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      return this.decoded["employee_id"];
    }
  }
  public getAllowHRRequest() {
    if (localStorage.getItem("auth_token")) {
      this.decoded = jwt_decode(localStorage.getItem("auth_token"));
      return this.decoded["allow_hr_request"];
    }
  }
  setProjectName(filter) {
    if (filter == "") this._cookieService.remove("prj_nm");
    this._cookieService.put("prj_nm", btoa(filter.toString()));
  }
  public getProjectName() {
    if (this._cookieService.get("prj_nm")) {
      return atob(this._cookieService.get("prj_nm"));
    } else return "";
  }
  setProjectID(id) {
    if (id == "") this._cookieService.remove("prj_id");
    this._cookieService.put("prj_id", btoa(id.toString()));
  }
  public getProjectID() {
    if (this._cookieService.get("prj_id")) {
      return atob(this._cookieService.get("prj_id"));
    } else return "";
  }

  setApplyFilterProjectName(filter) {
    if (filter == "") this._cookieService.remove("_flt_pn");
    this._cookieService.put("_flt_pn", btoa(filter.toString()));
  }
  public getApplyFilterProjectName() {
    if (this._cookieService.get("_flt_pn")) {
      return atob(this._cookieService.get("_flt_pn"));
    } else return "";
  }

  public setApplyFilterDealId(deal_id: any) {
    if (deal_id == "") this._cookieService.remove("_flt_did");
    this._cookieService.put("_flt_did", btoa(deal_id.toString()));
  }
  public getApplyFilterDealId() {
    if (this._cookieService.get("_flt_did")) {
      return atob(this._cookieService.get("_flt_did"));
    } else return "";
  }
  public setApplyFilterWorkdayId(wd_id: any) {
    if (wd_id == "") this._cookieService.remove("_flt_wid");
    this._cookieService.put("_flt_wid", btoa(wd_id.toString()));
  }
  public getApplyFilterWorkdayId() {
    if (this._cookieService.get("_flt_wid")) {
      return atob(this._cookieService.get("_flt_wid"));
    } else return "";
  }
  public getTheme() {
    if (this._cookieService.get("thm")) {
      return atob(this._cookieService.get("thm"));
    } else return "";
  }
  public setTheme(themeName: string) {
    if (themeName == "") this._cookieService.remove("thm");
    this._cookieService.put("thm", btoa(themeName));
  }
  public getProjectData(project: string) {
    let body = {
      project_name: null,
    };
    body.project_name = project;

    return this.http.post(
      environment.base_url + "get_data_project_list/",
      body
    );
  }

  public getProjectForMetaData() {
    return localStorage.getItem("project");
  }

  public setProjectForMetaData(project: string) {
    localStorage.setItem("project", project);
  }

  public getQatalogStage() {
    return localStorage.getItem("qatalogStage");
  }

  public setQatalogStage(qatalogStage: string) {
    localStorage.setItem("qatalogStage", qatalogStage);
  }
  public getVerificationStage() {
    return localStorage.getItem("verify_stage");
  }

  public setVerificationStage(v_stage: string) {
    localStorage.setItem("verify_stage", v_stage);
  }

  public getDevopsQatalogStage() {
    return localStorage.getItem("devops_qatalog");
  }

  public setDevopsQatalogStage(v_stage: string) {
    localStorage.setItem("devops_qatalog", v_stage);
  }

  public getInfosecQatalogStage() {
    return localStorage.getItem("infosec_qatalog");
  }

  public setInfosecQatalogStage(v_stage: string) {
    localStorage.setItem("infosec_qatalog", v_stage);
  }

  public getDeliveryQatalogStage() {
    return localStorage.getItem("delivery_qatalog");
  }

  public setDeliveryQatalogStage(v_stage: string) {
    localStorage.setItem("delivery_qatalog", v_stage);
  }

  public getTestingQatalogStage() {
    return localStorage.getItem("testing_qatalog");
  }

  public setTestingQatalogStage(v_stage: string) {
    localStorage.setItem("testing_qatalog", v_stage);
  }

  public getTabIndex() {
    return localStorage.getItem("tab_index");
  }

  public setTabIndex(index: any) {
    localStorage.setItem("tab_index", index);
  }
  public getUserEmail(input: string): string {
    if (!input) {
      return "";
    } else {
      let parts = input.split(" ");
      if (parts.length > 1) parts.splice(1, 0, ".");
      parts.push("@quantiphi.com");
      let email = parts.join("");
      email = email.toLowerCase();
      return email;
    }
  }
  project_selected = new Subject<any>();
  project_selected_for_workflow = new Subject<any>();
  draft_catalog_tabs = new Subject<any>();
  verification_catalog_tabs = new Subject<any>();
  project_change_meta_data = new Subject<any>();
  project_change_milestones = new Subject<any>();
  project_change_workflow = new Subject<any>();
  project_change_mom = new Subject<any>();
  project_change_audit_master = new Subject<any>();
  project_change_rca = new Subject<any>();
  url_project_change = new Subject<any>();
  // project_change_checklist = new Subject<any>();
  project_change_auditdetails = new Subject<any>();
  prmission_selected = new Subject<any>();
  prmission_selected_for_proj_info = new Subject<any>();
  sidebarToggle = new Subject<any>();
  verification_to_audit = new Subject<any>();

  sendClickEvent() {
    this.project_selected.next(null);
  }
  sendPermissionClickEvent() {
    this.prmission_selected.next(null);
  }
  // sendTabChangeEvent() {
  //   this.tab_change.next();
  // }
  sendProjChangeEvent() {
    this.prmission_selected_for_proj_info.next(null);
  }
  sendProjectSelectedForWorkFlow() {
    this.project_selected_for_workflow.next(null);
  }
  sendDraftCatalogTabsEvent() {
    this.draft_catalog_tabs.next(null);
  }

  sendVerificationCatalogTabsEvent() {
    this.verification_catalog_tabs.next(null);
  }

  public getEmpIds(designation: string) {
    let body = {
      designation: null,
    };
    body.designation = designation;
    // body.designation = this.tokenGenerater(designation);
    return this.http
      .get(environment.user_mng_url + "users/list")
      .pipe(retry(3));
    // return this.http.post(environment.base_url + "get_users_list/", body);
  }
  public getReleaseNotes() {
    return this.http.get(environment.base_url + "get_release_notes/");
  }

  //Function to extract user name from user email
  public getNameFromEmail(email: string): string {
    if (email && !email.includes("@")) return email;

    let name = _.chain(email).split("@").head().replace(".", " ").value();

    const firstDigitIndex = name.search(/\d/);
    if (firstDigitIndex != -1)
      name = name.slice(0, firstDigitIndex) + " " + name.slice(firstDigitIndex);

    name = _.startCase(name);

    return name;
  }

  //Function to extract user email from user name
  public getEmailFromName(name: string): string {
    if (!name) return "";

    let emailParts = name.split(" ");
    if (emailParts.length > 1) emailParts.splice(1, 0, ".");
    emailParts.push("@quantiphi.com");
    let email = emailParts.join("").toLowerCase();
    return email;
  }

  //Function to rename filter keys
  renameKey(obj: any, oldKey: string, newKey: string): any {
    if (obj?.hasOwnProperty(oldKey)) {
      obj[newKey] = obj[oldKey];
      delete obj[oldKey];
    }
    return obj;
  }

  //Function to flip an object ( key-> value and value-> key )
  objectFlip(obj: any): any {
    const ret = {};
    Object.keys(obj).forEach((key) => {
      ret[obj[key]] = key;
    });
    return ret;
  }
  public getUserName(input: string): string {
    if (!input) {
      return "";
    }
    if (!input.includes("@")) {
      return input;
    } else {
      const cleanedInput = input
        .trim()
        .split("@")[0]
        ?.replace(/[^\w]/g, " ")
        .split(" ");
      const capitalizedInput = cleanedInput
        ?.map((word) => {
          return word[0]?.toUpperCase() + word.substring(1);
        })
        .join(" ");

      return capitalizedInput;
    }
  }

  //Funtion to get name of file from Signed URL
  getFileNameFromSignedURL(url: string) {
    const fileName = url.substring(
      url.lastIndexOf("/") + 1,
      url.lastIndexOf("?")
    );
    const decodedFileName = decodeURIComponent(fileName);
    return decodedFileName;
  }

  //Function to get the current date in DD-MMM-YYYY HH:MM format

  public getTodayDate(): string {
    const formattedDate = moment().format("DD-MMM-YYYY hh:mm A");
    return formattedDate;
  }

  //Function to get range of last 3 months
  getLastMonthsRange(numberOfMonths: number): [string, string] {
    const currentDate = moment();
    const startDate = currentDate
      .clone()
      .subtract(numberOfMonths - 1, "months")
      .startOf("month");
    const endDate = currentDate.clone().endOf("month");

    return [startDate.format("YYYY-MM"), endDate.format("YYYY-MM")];
  }

  //Function to convert the month range in date format
  getDateRangeInDateFormat(dateRange: [string, string]): [string, string] {
    const startDate = moment(dateRange[0], "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");

    const endDate = moment(dateRange[1], "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");
    return [startDate, endDate];
  }

  processSheetForRFPManagement(body, file) {
    const upload_data = new FormData();
    upload_data.append("QACellValue", body["QACellValue"]);
    upload_data.append("answerCellValue", body["answerCellValue"]);
    upload_data.append("pageName", body["pageName"]);
    upload_data.append("sheetName", body["sheetName"]);
    upload_data.append("link", body["link"]);
    upload_data.append("file", file, file.name);
    return this.http.post(
      environment.base_url + "project_status_api/",
      upload_data,
      file
    );
  }
  public processDriveSheet(payload: any) {
    let requestPayload = {
      file_path: payload.link,
      sheetName: payload.sheetName,
      sub_sheet:
        typeof payload.pageName == "string"
          ? payload.pageName
          : payload.pageName.value,
      input_range: payload.QACellValue,
      output_range: payload.answerCellValue,
    };
    return this.http.post(
      environment.base_url + "rfp/rpf_management/",
      requestPayload
    );
  }
  processLocalFileUpload(file) {
    const upload_data = new FormData();

    upload_data.append("file", file, file.name);
    return this.http.post(
      environment.base_url + "upload_rfp_items/",
      upload_data,
      file
    );
  }

  public listDocumentsFromPipedrive(dealID) {
    return this.http.post(
      environment.base_url + "pipedrive/list_document_from_pipedrive/",
      { deal_id: dealID }
    );
  }
  public getSOWFile(document_id) {
    return this.http.post(
      environment.base_url + "pipedrive/download_document_from_pipedrive/",
      {
        document_id: document_id,
      }
    );
  }
}
