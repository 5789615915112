import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataViewModule } from "primeng/dataview";
import { PanelModule } from "primeng/panel";
import { PickListModule } from "primeng/picklist";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CheckboxModule } from "primeng/checkbox";
import { ButtonModule } from "primeng/button";
import { MenubarModule } from "primeng/menubar";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CardModule } from "primeng/card";
import { MenuModule } from "primeng/menu";
import { SidebarModule } from "primeng/sidebar";
import { DialogModule } from "primeng/dialog";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { TableModule } from "primeng/table";
import { StepsModule } from "primeng/steps";
import { DropdownModule } from "primeng/dropdown";
// import { ChipModule } from 'primeng/chip';
import { InputTextareaModule } from "primeng/inputtextarea";
import { FileUploadModule } from "primeng/fileupload";
import { ToastModule } from "primeng/toast";
import { MultiSelectModule } from "primeng/multiselect";
import { InputSwitchModule } from "primeng/inputswitch";
import { TooltipModule } from "primeng/tooltip";
import { ToggleButtonModule } from "primeng/togglebutton";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ChartModule } from "primeng/chart";
import { ProgressBarModule } from "primeng/progressbar";
import { NgCircleProgressModule } from "ng-circle-progress";
import { TieredMenuModule } from "primeng/tieredmenu";
import {KnobModule} from 'primeng/knob';
import { PaginatorModule } from "primeng/paginator";
import { TabViewModule } from "primeng/tabview";
import { MessageService } from "primeng/api";
import { CalendarModule } from "primeng/calendar";
import { RadioButtonModule } from "primeng/radiobutton";
import { SelectButtonModule } from "primeng/selectbutton";
import { RatingModule } from "primeng/rating";
import { InputTextModule } from "primeng/inputtext";
import { RippleModule } from "primeng/ripple";
import { BadgeModule } from "primeng/badge";
import { ChipModule } from "primeng/chip";
import { ScrollTopModule } from "primeng/scrolltop";
import { SkeletonModule } from "primeng/skeleton";
import { AvatarModule } from "primeng/avatar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { ToolbarModule } from "primeng/toolbar";
import { SpeedDialModule } from "primeng/speeddial";
import { CarouselModule } from "primeng/carousel";
import { AccordionModule } from "primeng/accordion";
import { OrderListModule } from "primeng/orderlist";
import { ImageModule } from "primeng/image";
import { MenuItem } from "primeng/api";
import { EditorModule } from "primeng/editor";
import { TagModule } from "primeng/tag";
import { MegaMenuModule } from "primeng/megamenu";
import { OrganizationChartModule } from "primeng/organizationchart";
import { TimelineModule } from "primeng/timeline";
import { BlockUIModule } from "primeng/blockui";
import { ChipsModule, Chips } from "primeng/chips";
import { DividerModule } from "primeng/divider";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -10,
      maxPercent: 100,
      outerStrokeGradient: true,
      outerStrokeWidth: 10,
      // "outerStrokeColor": "#06cca9",
      outerStrokeGradientStopColor: "#06cca9",
      innerStrokeColor: "#e7e8ea",
      innerStrokeWidth: 10,
      title: "Compliant",
      titleFontWeight: "700",
      titleFontSize: "28",
      animateTitle: true,
      animationDuration: 1000,
      showTitle: true,
      subtitle: "0%",
      subtitleColor: "#223d7f",
      subtitleFontSize: "42",
      subtitleFontWeight: "700",
      showSubtitle: true,
      showUnits: false,
      clockwise: false,
      responsive: true,
    }),
  ],
  exports: [
    CheckboxModule,
    ReactiveFormsModule,
    ToggleButtonModule,
    FormsModule,
    ButtonModule,
    MenubarModule,
    SidebarModule,
    TieredMenuModule,
    MenuModule,
    DialogModule,
    DynamicDialogModule,
    ChipModule,
    ProgressBarModule,
    TableModule,
    StepsModule,
    NgCircleProgressModule,
    DropdownModule,
    InputTextareaModule,
    FileUploadModule,
    ToastModule,
    MultiSelectModule,
    InputSwitchModule,
    TooltipModule,
    DataViewModule,
    PanelModule,
    InputTextModule,
    PickListModule,
    PaginatorModule,
    BreadcrumbModule,
    CardModule,
    OverlayPanelModule,
    MessageModule,
    MessagesModule,
    AutoCompleteModule,
    ScrollPanelModule,
    ChartModule,
    TabViewModule,
    RadioButtonModule,
    SelectButtonModule,
    CalendarModule,
    RatingModule,
    RippleModule,
    BadgeModule,
    ScrollTopModule,
    SkeletonModule,
    AvatarModule,
    ConfirmDialogModule,
    VirtualScrollerModule,
    ToolbarModule,
    SpeedDialModule,
    CarouselModule,
    AccordionModule,
    OrderListModule,
    ImageModule,
    EditorModule,
    TagModule,
    MegaMenuModule,
    OrganizationChartModule,
    TimelineModule,
    BlockUIModule,
    ChipModule,
    ChipsModule,
    KnobModule,
    DividerModule,
  ],
})
export class PrimeNgModule {}
