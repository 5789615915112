import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FlyerManagementServiceService {
  constructor(private http: HttpClient) {}

  public getAllFlyers() {
    return this.http.get(environment.base_url + "flyer/get_flyers/");
  }
  public getActiveFlyers() {
    return this.http.get(environment.base_url + "flyer/get_active_flyers/");
  }
  public addFlyer(flyer, is_active) {
    let fileData = new FormData();
    fileData.append("file", flyer);
    fileData.append("is_active", is_active);
    return this.http.post(environment.base_url + "flyer/add_flyer/", fileData);
  }
  public deleteFlyer(flyers_id) {
    let body = {
      flyers_id: [flyers_id],
    };
    return this.http.post(environment.base_url + "flyer/delete_flyer/", body);
  }
  public changeFlyerActiveStatus(flyers, status) {
    let body = {
      flyers: flyers,
      status: status,
    };
    return this.http.post(
      environment.base_url + "flyer/change_flyers_status/",
      body
    );
  }
}
